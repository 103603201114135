import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { TbChartInfographic, TbLockAccessOff } from "react-icons/tb";
import { MdMiscellaneousServices } from "react-icons/md";
import { PiRainbowCloudFill } from "react-icons/pi";
import { BsPersonGear } from "react-icons/bs";
import { LuMonitorDot } from "react-icons/lu";
import { RiCommunityLine } from "react-icons/ri";
import { SiGithubactions } from "react-icons/si";
import GeneralInfo from "./Tabs/GeneralInfo";
import Services from "./Tabs/Services";
import Cloud from "./Tabs/Cloud";
import Access from "./Tabs/Access";
import Employess from "./Tabs/Employess";
import Workstations from "./Tabs/Workstations";
import ResponseUnits from "./Tabs/ResponseUnits";
import Hubs from "./Tabs/Hubs";

const CompanyProfile = (props) => {
  const location = useLocation();
  // console.log(props);
  props.onDataChange(location.pathname);
  const [tabPoint, setTabPoint] = useState(1);
  return (
    <section className="h-full w-full text-white flex justify-center p-3 gap-x-2">
      <div className="basis-1/5 bg-primary p-3 space-y-2 h-max rounded-lg">
        <div
          onClick={() => setTabPoint(1)}
          className={
            (tabPoint === 1
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <TbChartInfographic className=" h-7 w-7" />
          <p className="capitalize font-semibold">General Info</p>
        </div>
        <div
          onClick={() => setTabPoint(2)}
          className={
            (tabPoint === 2
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <MdMiscellaneousServices className=" h-7 w-7" />
          <p className="capitalize font-semibold">Services Settings</p>
        </div>
        <div
          onClick={() => setTabPoint(3)}
          className={
            (tabPoint === 3
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <PiRainbowCloudFill className=" h-7 w-7" />
          <p className="capitalize font-semibold">Cloud Signaling monitoring</p>
        </div>
        <div
          onClick={() => setTabPoint(4)}
          className={
            (tabPoint === 4
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <TbLockAccessOff className=" h-7 w-7" />
          <p className="capitalize font-semibold">access rights</p>
        </div>
        <div
          onClick={() => setTabPoint(5)}
          className={
            (tabPoint === 5
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <BsPersonGear className=" h-7 w-7" />
          <p className="capitalize font-semibold">employees</p>
        </div>
        <div
          onClick={() => setTabPoint(6)}
          className={
            (tabPoint === 6
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <LuMonitorDot className=" h-7 w-7" />
          <p className="capitalize font-semibold">workstations</p>
        </div>
        <div
          onClick={() => setTabPoint(7)}
          className={
            (tabPoint === 7
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <RiCommunityLine className=" h-7 w-7" />
          <p className="capitalize font-semibold">Response units(RRU)</p>
        </div>
        <div
          onClick={() => setTabPoint(8)}
          className={
            (tabPoint === 8
              ? "bg-secondary"
              : " bg-transparent text-gray-500") +
            " flex justify-start items-center gap-x-2 rounded-full px-4 py-2 cursor-pointer"
          }
        >
          <SiGithubactions className=" h-7 w-7" />
          <p className="capitalize font-semibold">Hubs</p>
        </div>
      </div>
      <div className="basis-4/5 bg-primary p-3 rounded-lg h-full w-full">
        <div className={(tabPoint === 1 ? "block" : "hidden") + " "}>
          <GeneralInfo />
        </div>
        <div className={(tabPoint === 2 ? "block" : "hidden") + " "}>
          <Services />
        </div>
        <div className={(tabPoint === 3 ? "block" : "hidden") + " "}>
          <Cloud />
        </div>
        <div className={(tabPoint === 4 ? "block" : "hidden") + " "}>
          <Access />
        </div>
        <div className={(tabPoint === 5 ? "block" : "hidden") + " "}>
          <Employess />
        </div>
        <div className={(tabPoint === 6 ? "block" : "hidden") + " "}>
          <Workstations />
        </div>
        <div className={(tabPoint === 7 ? "block" : "hidden") + " "}>
          <ResponseUnits />
        </div>
        <div className={(tabPoint === 8 ? "block" : "hidden") + " "}>
          <Hubs />
        </div>
      </div>
    </section>
  );
};

export default CompanyProfile;
