import { createSlice } from "@reduxjs/toolkit";
import { toast, ToastContainer } from "react-toastify";
import { setCookie } from "../../Frontend/Helpers/Session";
import Cookies from "js-cookie";

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: "",
    errors: [],
    device_id_status: false,
  },

  reducers: {
    getnotifications: (state, action) => {
      state.notifications = action.payload;
      // console.log(action.payload.data);
      toast.info(action.payload.data.content, {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    getErrors: (state, action) => {
      state.errors = action.payload;
      //   console.log(action.payload, "error from reducer")
      toast.error(`${action.payload.message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  },
});
<ToastContainer />;

// Action creators are generated for each case reducer function
export const { getnotifications, getErrors } = notificationSlice.actions;

export default notificationSlice.reducer;
