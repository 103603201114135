// import { Redirect } from "react-router-dom"
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getLoginApi } from "../../Services/API/LoginApi";
import { getErrors, getLoginInfoSuccess } from "../Reducers/LoginReducer";

// Watchers
function* LoginSaga() {
  yield takeEvery("login/getloginInfo", getLoginData);
}

// Sagas
function* getLoginData(action) {
  // console.log(action.payload, "saga payload");

  try {
    const loginInfo = yield call(getLoginApi, action.payload);
    // console.log(loginInfo, "otp saga response")

    if (loginInfo.request.status === 200) {
      yield put(getLoginInfoSuccess(loginInfo));
    } else {
      //   console.log(loginInfo, "request error");

      yield put(getErrors(loginInfo));
    }
  } catch (error) {
    yield put(getErrors(error));
  }
}

export default function* rootSaga() {
  yield all([fork(LoginSaga)]);
}
