import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight, FaFire } from "react-icons/fa";
import { VscServerProcess } from "react-icons/vsc";
import { SiCountingworkspro } from "react-icons/si";
import { IoMdCheckboxOutline } from "react-icons/io";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import { GoAlert, GoClock, GoStopwatch } from "react-icons/go";
import { RiListSettingsFill } from "react-icons/ri";
import { CiCircleMore } from "react-icons/ci";
import { SlCalender, SlCallOut } from "react-icons/sl";
import { FiPhoneCall } from "react-icons/fi";
import { TbBrandUnity, TbTimeDurationOff } from "react-icons/tb";
import { PiCrownLight, PiListPlusBold } from "react-icons/pi";
import { BiSolidSelectMultiple, BiSolidCommentDetail } from "react-icons/bi";
import axiosInstance from "../../../Services/Interceptor";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Investigation = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [count, setCount] = useState(0);
  const { userProfile } = useSelector((state) => state.userProfile);
  const { state } = useLocation();
  const [processTab, setProcessTab] = useState(1);
  const [responsiblePerson, setResponsiblePerson] = useState([]);
  const [responsibleUnit, setResponsibleUnit] = useState([]);
  const [investigationLog, setInvestigationLog] = useState([]);
  const [ResponsiblePersonActionData, setResponsiblePersonActionData] =
    useState(null);
  const [incidentData, setIncidentData] = useState([]);
  const navigate = useNavigate();

  const [selectedAction, setSelectedAction] = useState({});

  useEffect(() => {
    // responsible person
    axiosInstance
      .get(
        `${baseURL}hub/users/${state?.selectedPendingData.data.hub_serial_id}/responsible-person/`
      )
      .then((response) => {
        setResponsiblePerson(response.data.results);
      })
      .catch((error) => {
        console.log(error.message);
      });
    //responsible unit
    axiosInstance
      .get(
        `${baseURL}hub/users/${state?.selectedPendingData.data.hub_serial_id}/responsible-unit/`
      )
      .then((response) => {
        setResponsibleUnit(response.data.results);
      })
      .catch((error) => {
        console.log(error.message);
      });

    // getting responsible person action
    axiosInstance
      .get(baseURL + `notification/operator/responsible-person/action/`)
      .then(function (response) {
        setResponsiblePersonActionData(response.data);
      })
      .catch((error) => {
        console.log(error, "  error");
      });
    // getting incident data
    axiosInstance
      .get(baseURL + `notification/operator/action/`)
      .then(function (response) {
        setIncidentData(response.data);
      })
      .catch((error) => {
        console.log(error, "  error");
      });

    //investigation data
    axiosInstance
      .get(
        baseURL +
          `notification/operator/process/${state?.selectedPendingData.id}/`
      )
      .then(function (response) {
        setInvestigationLog(response.data);
      })
      .catch((error) => {
        console.log(error, "  error");
      });
  }, [count]);

  // console.log("state",state)
  const handleResponsiblePersonAction = (person_id, action_id) => {
    setSelectedAction((prevSelected) => ({
      ...prevSelected,
      [person_id]: action_id,
    }));
    axiosInstance
      .post(
        baseURL +
          `notification/operator/process/${state?.selectedPendingData.id}/`,
        {
          notification_id: state?.selectedPendingData.id,
          responsible_person: {
            person_id: person_id,
            action_id: parseInt(action_id),
          },
        }
      )
      .then(function (response) {
        setCount(count + 1);
      })
      .catch((error) => {
        console.log(error, "  error person action");
      });
  };
  const [monitorMessage, setMonitorMessage] = useState("");
  const { register, handleSubmit, reset } = useForm();
  const incidentMessage = (data) => {
    setMonitorMessage(data.message);

    axiosInstance
      .post(
        baseURL +
          `notification/operator/process/${state?.selectedPendingData.id}/`,
        {
          notification_id: state?.selectedPendingData.id,
          operator: {
            message: data.message,
          },
        }
      )
      .then((response) => {
        setCount(count + 1);
        reset();
      })
      .catch((error) => {
        // console.log(error.message);
        setCount(count + 1);
        reset();
      });
  };

  const [incidentActionId, setIncidentActionId] = useState();
  const handleFinishProcessing = () => {
    axiosInstance
      .post(
        baseURL +
          `notification/operator/process/${state?.selectedPendingData.id}/`,
        {
          notification_id: state?.selectedPendingData.id,
          operator: {
            finished_processed: true,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setCount(count + 1);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleIncident = (incidentActionId) => {
    axiosInstance
      .post(
        baseURL +
          `notification/operator/process/${state?.selectedPendingData.id}/`,
        {
          notification_id: state?.selectedPendingData.id,
          operator: {
            action_id: parseInt(incidentActionId),
          },
        }
      )
      .then((response) => {
        setCount(count + 1);
      })
      .catch((error) => {
        setCount(count + 1);
        // console.log(error.message);
      });
  };

  // stopwatch
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => setTime(time + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time, count]);
  const hours = Math.floor(time / 360000);
  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);
  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);
  // Milliseconds calculation
  const milliseconds = time % 100;

  return (
    <div className="flex justify-between gap-4 h-dvh h-full  w-full px-4">
      <div className=" h-dvh w-3/4  space-y-8">
        <div className="flex justify-start items-center gap-2 pt-10 ">
          {/* process */}
          <div className="flex justify-start items-center gap-4 border border-slate-300 shadow shadow-slate-200 w-max rounded px-4 text-[10px] 2xl:text-sm py-1 text-gray-500">
            <div className="flex justify-center items-center gap-1 font-semibold text-primary">
              <VscServerProcess className="h-3 w-3 " />
              <Link to="/" className="hover:text-brandColor">
                <p className="">Investigation</p>
              </Link>
            </div>
            <FaLongArrowAltRight className="h-3 w-3 " />
            <div className="flex justify-center items-center gap-1 font-semibold text-brandColor">
              <SiCountingworkspro className="h-3 w-3 " />
              <p>Processing</p>
            </div>
            <FaLongArrowAltRight className="h-3 w-3 " />
            <div className="flex justify-center items-center gap-1 font-semibold text-slate-500">
              <IoMdCheckboxOutline className="h-3 w-3 " />
              <p>Verification</p>
            </div>
            <FaLongArrowAltRight className="h-3 w-3 " />
            <div className="flex justify-center items-center gap-1 font-semibold text-slate-500">
              <HiOutlineCheckBadge className="h-3 w-3 " />
              <p>Resolved</p>
            </div>
          </div>
          {/* alarm type */}
          <div className="flex justify-center items-center gap-1 font-semibold text-primary border border-slate-300 shadow shadow-slate-200 w-max rounded px-4 text-[10px] 2xl:text-sm py-1">
            <GoAlert className="h-3 w-3 " />
            <p>{state?.selectedPendingData.notification_type}</p>
          </div>
          {/* time */}
          <div className="flex justify-center items-center gap-1 font-semibold text-primary border border-slate-300 shadow shadow-slate-200 w-max rounded px-4 text-[10px] 2xl:text-sm py-1">
            <GoClock className="h-3 w-3 " />
            <p className="capitalize">
              generated at {state?.selectedPendingData.time}
            </p>
          </div>
        </div>
        <div className="ml-20 space-y-8">
          <div className="w-full flex justify-start items-center gap-1 bg-white font-medium text-primary border border-slate-300 shadow shadow-slate-200 rounded-lg p-8">
            <p className="font-">{state?.selectedPendingData.content}</p>
          </div>
          {/* process tabs */}
          <div className="flex justify-between items-center w-full text-xs 2xl:text-sm font-medium pb- border-b-2 border-slate-200">
            <div className="flex justify-start items-center gap-6 ">
              <p
                className={
                  (processTab === 1
                    ? " border-b-2 border-primary text-primary"
                    : "text-gray-600/60") + " cursor-pointer"
                }
                onClick={() => setProcessTab(1)}
              >
                Event
              </p>
              <p
                className={
                  (processTab === 2
                    ? " border-b-2 border-primary text-primary"
                    : "text-gray-600/60") + " cursor-pointer"
                }
                onClick={() => setProcessTab(2)}
              >
                Responsible Person
              </p>
              <p
                className={
                  (processTab === 3
                    ? " border-b-2 border-primary text-primary"
                    : "text-gray-600/60") + " cursor-pointer"
                }
                onClick={() => setProcessTab(3)}
              >
                Dispatch Unit
              </p>
              <p
                className={
                  (processTab === 4
                    ? " border-b-2 border-primary text-primary"
                    : "text-gray-600/60") + " cursor-pointer"
                }
                onClick={() => setProcessTab(4)}
              >
                Incident
              </p>
            </div>
            {/* <div className="flex justify-center items-center gap-1 text-primary cursor-pointer">
              <RiListSettingsFill className="h-4 w-4 " />
              <p className="capitalize">Process setting</p>
            </div> */}
          </div>
          {/* process events */}
          <div>
            {/* Timelines */}
            <div
              className={
                (processTab === 1 ? "block" : "hidden") +
                " relative text-sm space-y-8 ml-8"
              }
            >
              <div className="relative z-10 flex justify-start items-center gap-2 text-primary cursor-pointer">
                <CiCircleMore className="h-6 w-6 text-slate-500" />
                <p className="">An investigation has been scheduled .</p>
                <p className="flex justify-center items-center gap-1 font-semibold text-xs text-primary pl-2">
                  <SlCalender className="h-3 w-3" />
                  <span>Date: {state?.selectedPendingData.date}</span>
                </p>
              </div>
              <div className="relative z-10 flex justify-start items-center gap-2 text-primary cursor-pointer">
                <SiCountingworkspro className="h-6 w-6 text-slate-500" />
                <p className="">
                  Process has been started by{" "}
                  <span className="italic">{userProfile.full_name}</span> .
                </p>
              </div>
              <div className="absolute -top-2 left-3  h-[25%] w-0.5 border border-dashed border-slate-400"></div>
              <div className="w-1/2 flex justify-end">
                <div
                  onClick={() => {
                    setProcessTab(2);
                    // timerstart();
                    setIsRunning(true);
                  }}
                  className="px-8 py-2 bg-brandColor text-white text-xs font-medium rounded flex justify-start items-center gap-2 cursor-pointer"
                >
                  <p className="uppercase">Next step</p>
                  <FaLongArrowAltRight className="h-3 w-3 " />
                </div>
              </div>
            </div>
            {/* responsible person */}
            <div
              className={
                (processTab === 2 ? "block" : "hidden") +
                " relative text-sm space-y-8 ml-8"
              }
            >
              <div className="bg-white border border-slate-200 p-8 rounded-lg shadow shadow-slate-200 space-y-4">
                {responsiblePerson.length === 0 ? (
                  <div className="flex flex-col justify-center items-center gap-4 bg-white border border-slate-200 p-8 rounded-lg shadow shadow-slate-200 text-gray-400">
                    <TbBrandUnity className="h-10 w-10" />
                    <p className="capitalize font-medium text-gray-600">
                      Currently no responsible person available
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="flex justify-between items-center gap-4 ">
                      {/* user  */}
                      {responsiblePerson?.map((item, index) => {
                        return (
                          <div
                            className="w-full border border-slate-200 rounded-lg text-[10px] 2xl:text-base"
                            key={index}
                          >
                            <div className="flex justify-between items-center px-4 pt-6 pb-2 border-b border-slate-200 font-medium ">
                              <span className="w-full flex-1">{item.name}</span>
                              <div className="flex justify-center items-center gap-2 font-semibold text-white bg-brandColor border border-slate-300 shadow shadow-slate-200 rounded px-4 py-1 cursor-pointer">
                                <a
                                  href={`tel: ${item.phone}`}
                                  className="capitalize"
                                >
                                  {item.phone}
                                </a>
                                <FiPhoneCall className="h-3 w-3 " />
                              </div>
                            </div>
                            {ResponsiblePersonActionData ? (
                              <div className="flex justify-between items-center gap-4 p-4">
                                {ResponsiblePersonActionData.data?.map(
                                  (itemAction, index) => {
                                    return (
                                      <div
                                        className="w-full flex justify-between items-center gap-2 text-[10px] 2xl:text-sm text-gray-500 "
                                        key={index}
                                        onClick={() =>
                                          handleResponsiblePersonAction(
                                            item.id,
                                            itemAction.id
                                          )
                                        }
                                      >
                                        <div
                                          className={`${
                                            selectedAction[item.id] ===
                                            itemAction.id
                                              ? itemAction.id === 1
                                                ? "bg-emerald-500 text-white"
                                                : "bg-red-500 text-white"
                                              : "bg-white"
                                          } flex justify-center items-center gap-2 font-semibold border border-slate-300 shadow shadow-slate-200 w-full rounded px-4 py-1 cursor-pointer`}
                                        >
                                          <p className="capitalize">
                                            {itemAction.action}
                                          </p>
                                          <SlCallOut className="h-3 w-3 " />
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <p>No Action available </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {/* Comment on event */}
                    <div className="border border-slate-200 p-4 rounded-lg shadow shadow-slate-200 space-y-4">
                      <div className="border-b border-slate-200 pb-2">
                        <div className="flex justify-start items-center gap-1 text-primary font-medium">
                          <p className="capitalize">Comment on event</p>
                          <BiSolidCommentDetail className="h-4 w-4 " />
                        </div>
                      </div>

                      <form onSubmit={handleSubmit(incidentMessage)}>
                        <input
                          placeholder="Press Enter after finish typing comment."
                          className="h-auto w-full border border-slate-200 p-3 rounded-lg outline-none focus:none"
                          {...register("message")}
                        />
                      </form>
                    </div>
                  </>
                )}
              </div>

              {responsiblePerson.length === 0 ? (
                <div className="w-1/2 flex justify-end">
                  <div
                    onClick={() => setProcessTab(3)}
                    className="px-8 py-2 bg-brandColor text-white text-xs font-medium rounded flex justify-start items-center gap-2 cursor-pointer"
                  >
                    <p className="uppercase">Next step</p>
                    <FaLongArrowAltRight className="h-3 w-3 " />
                  </div>
                </div>
              ) : (
                <div className="w-1/2 flex justify-end">
                  {monitorMessage === "" ? (
                    <div className="px-8 py-2 bg-gray-600 text-white  text-xs font-medium rounded flex justify-start items-center gap-2 cursor-not-allowed">
                      <p className="uppercase cursor-not-allowed">Next step</p>
                      <FaLongArrowAltRight className="h-3 w-3 " />
                    </div>
                  ) : (
                    <div
                      onClick={() => setProcessTab(3)}
                      className="px-8 py-2 bg-brandColor text-white text-xs font-medium rounded flex justify-start items-center gap-2 cursor-pointer"
                    >
                      <p className="uppercase">Next step</p>
                      <FaLongArrowAltRight className="h-3 w-3 " />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Dispatch Unit */}
            <div
              className={
                (processTab === 3 ? "block" : "hidden") +
                " relative text-sm space-y-8 ml-8"
              }
            >
              <div className="bg-white border border-slate-200 p-8 rounded-lg shadow shadow-slate-200 space-y-4">
                {responsibleUnit.length === 0 ? (
                  <div className="flex flex-col justify-center items-center gap-4 bg-white border border-slate-200 p-8 rounded-lg shadow shadow-slate-200 text-gray-400">
                    <TbBrandUnity className="h-10 w-10" />
                    <p className="capitalize font-medium text-gray-600">
                      Currently no unit is Dispatch available
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="flex justify-between items-center gap-4 ">
                      {/* user 1 */}
                      {responsibleUnit?.map((item, index) => {
                        return (
                          <div
                            className="w-full border border-slate-200 rounded-lg"
                            key={index}
                          >
                            <div className="flex justify-between items-center px-4 pt-6 pb-2 border-b border-slate-200 font-medium ">
                              <span className="w-full flex-1">{item.name}</span>
                              <div className="flex justify-center items-center gap-2 font-semibold text-white bg-brandColor border border-slate-300 shadow shadow-slate-200 rounded px-4 text-sm py-1 cursor-pointer">
                                <p className="capitalize">{item.phone}</p>
                                <FiPhoneCall className="h-3 w-3 " />
                              </div>
                            </div>
                            <div className="p-4 flex justify-between items-center gap-2 text-gray-500">
                              <div className="flex justify-center items-center gap-2 font-semibold  border border-slate-300 shadow shadow-slate-200 w-full rounded px-4 text-sm py-1 cursor-pointer">
                                <p className="capitalize">Call received</p>
                                <SlCallOut className="h-3 w-3 " />
                              </div>
                              <div className="flex justify-center items-center gap-2 font-semibold  border border-slate-300 shadow shadow-slate-200 w-full rounded px-4 text-sm py-1 cursor-pointer">
                                <p className="capitalize">Call not received</p>
                                <SlCallOut className="h-3 w-3 " />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <div className="w-1/2 flex justify-end">
                <div
                  onClick={() => setProcessTab(4)}
                  className="px-8 py-2 bg-brandColor text-white text-xs font-medium rounded flex justify-start items-center gap-2 cursor-pointer"
                >
                  <p className="uppercase">Next step</p>
                  <FaLongArrowAltRight className="h-3 w-3 " />
                </div>
              </div>
            </div>
            {/* Incident */}
            <div
              className={
                (processTab === 4 ? "block" : "hidden") +
                " relative text-sm space-y-8 ml-8"
              }
            >
              <div className="bg-white border border-slate-200 p-8 rounded-lg shadow shadow-slate-200 space-y-4">
                <div className="border-b border-slate-200 pb-2">
                  <div className="flex justify-start items-center gap-1 text-primary font-medium">
                    <p className="capitalize">incident specification</p>
                    <BiSolidSelectMultiple className="h-4 w-4 " />
                  </div>
                </div>
                <div className="flex justify-start items-center flex-wrap gap-4 ">
                  {incidentData.data?.map((item, index) => {
                    return (
                      <div
                        className={`flex flex-col justify-center items-center gap-4 border border-slate-200 hover:border-brandColor/20 p-8 rounded-lg shadow shadow-slate-200 hover:shadow-brandColor/10   hover:text-brandColor cursor-pointer ${
                          incidentActionId === item.id
                            ? "text-brandColor border-brandColor"
                            : "text-gray-400"
                        } `}
                        key={index}
                        onClick={() => (
                          handleIncident(item.id), setIncidentActionId(item.id)
                        )}
                      >
                        <TbTimeDurationOff className="h-10 w-10" />
                        <p className="capitalize font-medium ">{item.action}</p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                onClick={() => {
                  if (incidentActionId) {
                    setIsRunning(false);
                    handleFinishProcessing();
                    navigate("/");
                  }
                }}
                className={`  flex justify-end items-center gap-2 `}
              >
                <p
                  className={`${
                    incidentActionId
                      ? "bg-brandColor cursor-pointer"
                      : "bg-gray-500 cursor-not-allowed"
                  } px-8 py-2 text-white text-xs font-medium  rounded`}
                >
                  Finish Processing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" h-dvh overflow-auto w-1/4 flex flex-col gap-2 divide-y divide-slate-300 p-4 text-slate-600">
        <div className="py-2 space-y-3 text-xs 2xl:text-sm">
          {/* Hub owner */}
          <div className="flex justify-between">
            <p>Hub Owner :</p>
            <p className="flex justify-between items-center gap-1">
              <span>{state?.selectedPendingData?.user?.hub_owner.name}</span>{" "}
              <PiCrownLight className="h-4 w-4 text-brandColor" />
            </p>
          </div>
          {/* Alarm reporter */}
          <div className="flex justify-between">
            <p>Responder :</p>
            <p className="flex justify-between items-center gap-1">
              <span>{userProfile.full_name}</span>{" "}
              <VscServerProcess className="h-4 w-4 text-red-700" />
            </p>
          </div>
        </div>
        {/* Investigation log */}
        <div className="py-2 h-[70%] flex-col space-y-2 ">
          <div className="flex justify-between text-primary font-medium">
            <p className="text-sm 2xl:text-base">Investigation Log</p>
            <p className="flex justify-between items-center gap-1">
              <PiListPlusBold className="h-4 w-4 text-brandColor" />
            </p>
          </div>
          <div className="h-[95%] overflow-auto  pr-2 ">
            {investigationLog.length === 0 ? (
              <div className=" relative h-auto">
                <div className="absolute h-full w-1 border-l-2 border-slate-300 border-dashed  z-0 left-1"></div>
                <div className="pb-3 relative z-10 w-full flex justify-start items-center gap-x-2">
                  <div className="relative h-2.5 w-2.5 bg-brandColor/70 rounded-full"></div>
                  <p className="relative text-xs sm:text-sm flex justify-start items-center gap-x-2 w-full">
                    No data available
                  </p>
                </div>
              </div>
            ) : (
              investigationLog?.data.map((item, index) => {
                return (
                  <div key={index} className=" relative h-auto ">
                    <div className=" text-xs sm:text-sm w-full space-y-2">
                      <p>{item?.date}</p>
                      <div>
                        {item?.notification?.map((subItem, index) => {
                          return (
                            <div key={index} className=" relative h-auto ml-2">
                              <div className="absolute h-full w-1 border-l-2 border-slate-300 border-dashed  z-0 left-1"></div>
                              <div className="pb-2 relative z-10 w-full flex  justify-start items-center gap-x-2">
                                <div className="relative h-2.5 w-2.5 bg-brandColor/70 rounded-full"></div>
                                <div className="flex flex-col w-full">
                                  <p className="relative text-xs sm:text-sm flex justify-start items-center gap-x-2 w-full">
                                    Content: {subItem?.content} {subItem?.time}
                                  </p>
                                  
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        {/* Alarm generation */}
        <div className="py-2 space-y-1 text-sm">
          <p className="flex justify-start items-center gap-1 capitalize font-semibold text-primary ">
            <span>Alarm generation</span> <GoClock className="h-4 w-4 " />
          </p>
          <div className="flex justify-between">
            <p>Investigation Scheduled </p>
            <p>{state?.selectedPendingData.time}</p>
          </div>
        </div>
        {/* Investigation Duration */}
        <div className="py-2 space-y-1 text-sm">
          <p className="flex justify-start items-center gap-1 capitalize font-semibold text-primary ">
            <span>Duration</span> <GoStopwatch className="h-4 w-4 " />
          </p>
          <div className="flex justify-between">
            <p>Investigation Duration </p>
            <div className="stopwatch-container">
              <p className="stopwatch-time">
                {hours}:{minutes.toString().padStart(2, "0")}:
                {seconds.toString().padStart(2, "0")}:
                {milliseconds.toString().padStart(2, "0")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investigation;
