// import { Redirect } from "react-router-dom"
import { all,  fork, takeEvery } from "redux-saga/effects";


// Watchers
function* NotificationSaga() {
  yield takeEvery("notifications/getnotifications", setNotification);
}

// Sagas
function* setNotification(action) {
  // console.log(action.payload, "saga payload");

//   try {
//     const data = yield call(getForgetPassApi, action.payload);
//     // console.log(data, "resend saga response");

//     if (data.request.status == 200) {
//       yield put(getForgetPassMessageSuccess(data));
//     } else {
//       yield put(getErrors(data.response.data));
//     }
//   } catch (error) {
//     yield put(getErrors(error));
//   }
}

export default function* rootSaga() {
  yield all([fork(NotificationSaga)]);
}
