import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { TbChartInfographic, TbLockAccessOff } from "react-icons/tb";
import { MdMiscellaneousServices } from "react-icons/md";
import { PiRainbowCloudFill } from "react-icons/pi";
import { BsPersonGear } from "react-icons/bs";
import { LuMonitorDot } from "react-icons/lu";
import { RiCommunityLine } from "react-icons/ri";
import { SiGithubactions } from "react-icons/si";

const Objects = (props) => {
  const location = useLocation();
  // console.log(props);
  props.onDataChange(location.pathname);
  const [tabPoint, setTabPoint] = useState(1);
  return (
    <section className="h-full w-full text-white flex justify-center p-3 gap-x-2">
      <div className="basis-1/5 p-3 space-y-4 h-max rounded-lg">
        <h3 className="uppercase font-medium text-sm">
          Monitoring with Satarko
        </h3>
        <div className="bg-primary rounded-lg divide-y divide-gray-700">
          <div
            onClick={() => setTabPoint(1)}
            className={
              (tabPoint === 1 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">Active</p>
            <p>0</p>
          </div>
        </div>
        <div className="bg-primary rounded-lg divide-y divide-gray-700">
          <div
            onClick={() => setTabPoint(2)}
            className={
              (tabPoint === 2 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">General Info</p>
            <p>2</p>
          </div>
          <div
            onClick={() => setTabPoint(3)}
            className={
              (tabPoint === 3 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">Services Settings</p>
            <p>3</p>
          </div>
        </div>

        <div className="bg-primary rounded-lg divide-y divide-gray-700">
          <div
            onClick={() => setTabPoint(4)}
            className={
              (tabPoint === 4 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">
              Cloud Signaling monitoring
            </p>
            <p>4</p>
          </div>
          <div
            onClick={() => setTabPoint(5)}
            className={
              (tabPoint === 5 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">access rights</p>
            <p>2</p>
          </div>
          <div
            onClick={() => setTabPoint(6)}
            className={
              (tabPoint === 6 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">employees</p>
            <p>1</p>
          </div>
          <div
            onClick={() => setTabPoint(7)}
            className={
              (tabPoint === 7 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">workstations</p>
            <p>3</p>
          </div>
        </div>
        <div className="bg-primary rounded-lg divide-y divide-gray-700">
          <div
            onClick={() => setTabPoint(8)}
            className={
              (tabPoint === 8 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">Response units(RRU)</p>
            <p>0</p>
          </div>
          <div
            onClick={() => setTabPoint(9)}
            className={
              (tabPoint === 9 ? "text-white" : " text-gray-500") +
              " flex justify-between items-center gap-x-2 px-4 py-3 cursor-pointer"
            }
          >
            <p className="capitalize font-semibold">Hubs</p>
            <p>12</p>
          </div>
        </div>
      </div>
      <div className="basis-4/5 bg-primary p-3 rounded-lg h-full w-full">
        <div className={(tabPoint === 1 ? "block" : "hidden") + " "}>
          {/* <GeneralInfo /> */}1
        </div>
        <div className={(tabPoint === 2 ? "block" : "hidden") + " "}>
          {/* <Services /> */}2
        </div>
        <div className={(tabPoint === 3 ? "block" : "hidden") + " "}>
          {/* <Cloud /> */}3
        </div>
        <div className={(tabPoint === 4 ? "block" : "hidden") + " "}>
          {/* <Access /> */}4
        </div>
        <div className={(tabPoint === 5 ? "block" : "hidden") + " "}>
          {/* <Employess /> */}5
        </div>
        <div className={(tabPoint === 6 ? "block" : "hidden") + " "}>
          {/* <Workstations /> */}6
        </div>
        <div className={(tabPoint === 7 ? "block" : "hidden") + " "}>
          {/* <ResponseUnits /> */}7
        </div>
        <div className={(tabPoint === 8 ? "block" : "hidden") + " "}>
          {/* <Hubs /> */}8
        </div>
        <div className={(tabPoint === 9 ? "block" : "hidden") + " "}>
          {/* <Hubs /> */}9
        </div>
      </div>
    </section>
  );
};

export default Objects;
