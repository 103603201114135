import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import axiosInstance from "../../../Services/Interceptor";
import { useLocation } from "react-router-dom";

const UserList = (props) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [userList, setUserList] = useState();
  const location = useLocation();
  // console.log(location.pathname)
  props.onDataChange(location.pathname);
  useEffect(() => {
    axiosInstance
      // .get(baseURL + `activity/users/?hub_id=${id}`)
      .get(baseURL + "users/admin/")
      .then(function (response) {
        // console.log(response, " api response");
        setUserList(response.data);
      })
      .catch((error) => {
        console.log(error, "  error");
      });
  }, []);

  return (
    <section className="h-full w-full space-y-4">
      <div className="bg-gradient-to-r from-[#616161] to-[#3a3939] h-auto w-full py-4 px-4 rounded-t-md text-center text-xl font-semibold text-slate-200  flex justify-end items-center gap-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-7 h-7"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          />
        </svg>
        UserList
      </div>

      <div className="flex justify-end items-center gap-x-4">
        <p className="uppercase text-slate-200 text-sm font-semibold">
          filter by :
        </p>

        <Menu
          as="div"
          className="relative text-left flex justify-center items-center"
        >
          <div
            className={
              "inline-flex w-full gap-x-1 justify-center items-center rounded-md text-xs font-medium border border-slate-50 divide-x divide-solid divide-slate-50 shadow text-slate-200 "
            }
          >
            <p className="px-2 py-1  ">User Name</p>
            <Menu.Button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-6 py-1 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 right-0 top-5 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-[#3f3f3f] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      // onClick={() =>
                      //   handleChangeStatus("Ignore", item.id)
                      // }
                      className={`${
                        active ? "bg-primary text-slate-200" : "text-gray-400 "
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Ignore
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      // onClick={() =>
                      //   handleChangeStatus("Resolve", item.id)
                      // }
                      className={`${
                        active ? "bg-primary text-slate-200" : "text-gray-400 "
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm `}
                    >
                      Resolve
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu
          as="div"
          className="relative text-left flex justify-center items-center"
        >
          <div
            className={
              "inline-flex w-full gap-x-1 justify-center items-center rounded-md text-xs font-medium border border-slate-50 divide-x divide-solid divide-slate-50 shadow text-slate-200 "
            }
          >
            <p className="px-2 py-1  ">Hub</p>
            <Menu.Button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-6 py-1 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 right-0 top-5 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-[#3f3f3f] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      // onClick={() =>
                      //   handleChangeStatus("Ignore", item.id)
                      // }
                      className={`${
                        active ? "bg-primary text-slate-200" : "text-gray-400 "
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Ignore
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      // onClick={() =>
                      //   handleChangeStatus("Resolve", item.id)
                      // }
                      className={`${
                        active ? "bg-primary text-slate-200" : "text-gray-400 "
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm `}
                    >
                      Resolve
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu
          as="div"
          className="relative text-left flex justify-center items-center"
        >
          <div
            className={
              "inline-flex w-full gap-x-1 justify-center items-center rounded-md text-xs font-medium border border-slate-50 divide-x divide-solid divide-slate-50 shadow text-slate-200 "
            }
          >
            <p className="px-2 py-1  ">Arm / Disarm</p>
            <Menu.Button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-6 py-1 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 right-0 top-5 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-[#3f3f3f] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      // onClick={() =>
                      //   handleChangeStatus("Ignore", item.id)
                      // }
                      className={`${
                        active ? "bg-primary text-slate-200" : "text-gray-400 "
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Ignore
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      // onClick={() =>
                      //   handleChangeStatus("Resolve", item.id)
                      // }
                      className={`${
                        active ? "bg-primary text-slate-200" : "text-gray-400 "
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm `}
                    >
                      Resolve
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {/* userlist */}
      {/* {dashboardInfo && dashboardInfo.data ? ( */}
      {userList && userList.count ? (
        //  table
        <div className="h-[85%] overflow-auto">
          <table className=" bg-[#333333]  shadow-md border  border-primary min-w-full  divide-y divide-gray-700 border-collapse ">
            <thead className="bg-[#333333] font-semibold text-slate-200 rounded-t-lg ">
              <tr className="rounded-t-lg">
                <th
                  scope="col"
                  className="py-3.5 text-center text-sm font-bold border border-slate-400 opacity-90"
                >
                  User Name
                </th>
                <th
                  scope="col"
                  className="py-3.5 text-center text-sm font-bold border border-slate-400 opacity-90"
                >
                  Phone Number
                </th>

                <th
                  scope="col"
                  className="text-center px-3 py-3.5 text-sm font-bold
                         border border-slate-400"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="text-center px-3 py-3.5 text-sm font-bold
                         border border-slate-400"
                >
                  Time
                </th>
                <th
                  scope="col"
                  className="text-center px-3 py-3.5 text-sm font-bold
                         border border-slate-400"
                >
                  Hub Name
                </th>

                <th
                  scope="col"
                  className="text-center px-3 py-3.5 text-sm font-bold
                         border border-slate-400"
                >
                  Device Name
                </th>
                <th
                  scope="col"
                  className="text-center px-3 py-3.5 text-sm font-bold
                         border border-slate-400"
                >
                  User Type
                </th>

                <th
                  scope="col"
                  className="text-center px-3 py-3.5 text-sm font-bold
                         border border-slate-400"
                >
                  Status
                </th>
              </tr>
            </thead>

            <tbody className="bg-[#333333] text-slate-200 rounded-b-lg w-full h-auto overflow-auto ">
              {userList.results.map((item, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap py-2   text-sm font-semibold  border border-slate-300">
                    <p className=" text-center">Md. User Name</p>
                  </td>
                  <td className="whitespace-nowrap py-2   text-sm font-semibold  border border-slate-300">
                    <p className=" text-center">{item.phone}</p>
                  </td>

                  <td className="whitespace-nowrap py-2   text-sm font-semibold  border border-slate-300">
                    <p className=" text-center ">12/12/12</p>
                  </td>
                  <td className="whitespace-nowrap py-2   text-sm font-semibold  border border-slate-300">
                    <p className=" text-center ">12:32 Pm</p>
                  </td>
                  <td className="whitespace-nowrap py-2   text-sm font-semibold  border border-slate-300">
                    <p className=" text-center ">Voult</p>
                  </td>
                  <td className="whitespace-nowrap py-2   text-sm font-semibold  border border-slate-300">
                    <p className=" text-center ">dsfsdg</p>
                  </td>
                  <td className="whitespace-nowrap py-2   text-sm font-semibold  border border-slate-300">
                    <p className=" text-center ">{item.user_type}</p>
                  </td>
                  <td className="whitespace-nowrap py-2  text-slate-200  text-sm font-semibold  border border-slate-300 text-center">
                    {item.is_active ? (
                      <p className="text-green-600">Active</p>
                    ) : (
                      <p className="text-red-500">Inactive</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full w-full">
          <img
            className="h-52 w-auto object-contain "
            src="/images/loading.gif"
            alt="loader"
          />
        </div>
      )}
    </section>
  );
};

export default UserList;
