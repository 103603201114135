import Cookies from "js-cookie";
import React, { useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useDispatch } from "react-redux";
import { getnotifications } from "../../../Redux/Reducers/NotificationReducer";
export const Notification = (props) => {
  const dispatch = useDispatch();
  const port = "prod.satarko.com";
  // console.log(port);
  let authTokens = Cookies.get("access_token_monitor")
    ? Cookies.get("access_token_monitor")
    : null;

  // console.log(authTokens)

  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState(
    `wss://${port}/ws/operator/notifications/?token=${authTokens}`
  );

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      // console.log("Connected! Notification");
    },
    onClose: () => {
      // console.log("Disconnected! Notification");
    },
    // onMessage handler
    onMessage: (e) => {
      // console.log("data from ws", JSON.parse(e.data));
      if (JSON.parse(e.data).data) {
        dispatch(getnotifications(JSON.parse(e.data)));
      }
      // props.onDataChange(JSON.parse(e.data));
    },
  });
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  const [messageHistory, setMessageHistory] = useState([]);
  const [message1, setMessage1] = useState();
  const [message2, setMessage2] = useState();
  const [message3, setMessage3] = useState();
  const [message4, setMessage4] = useState();
  return (
    <div className="px-6 flex flex-col gap-y-3 text-white">
      {" "}
      {message1 ? (
        <>
          <p
            className={`${
              message1 === "true" ? "bg-green-500" : "bg-red-500"
            } w-full py-1 text-white font-bold"`}
          >
            {" "}
            Message1: {message1}
          </p>

          <p
            className={`${
              message2 === "true" ? "bg-green-500" : "bg-red-500"
            } w-full py-1 text-white font-bold"`}
          >
            {" "}
            Message2: {message2}
          </p>
          <p
            className={`${
              message3 === "true" ? "bg-green-500" : "bg-red-500"
            } w-full py-1 text-white font-bold"`}
          >
            {" "}
            Message3: {message3}
          </p>
          <p
            className={`${
              message4 === "true" ? "bg-green-500" : "bg-red-500"
            } w-full py-1 text-white font-bold"`}
          >
            {" "}
            Message4: {message4}
          </p>
        </>
      ) : (
        // <p className="bg-gray-700">No message</p>
        ""
      )}
    </div>
  );
};
