import { all } from "redux-saga/effects";
import LoginSaga from "./LoginSaga";
import ForgetPassSaga from "./ForgetPassSaga";
import NotificationSaga from "./NotificationSaga";

export default function* rootSaga() {
  yield all([
    LoginSaga(),
    ForgetPassSaga(),
    NotificationSaga(),
  ]);
}
