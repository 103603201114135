import axios from "axios";

export const getLoginApi = (payload) => {
  //   console.log(payload.phone, "top payload");
  const baseURL = process.env.REACT_APP_BASE_URL;
  // console.log(baseURL)

  return axios
    .post(baseURL + "users/operator/login/", {
      phone: payload.phone,
      password: payload.password,
    })
    .then(function (response) {
      // console.log(response, " api response")
      return response;
    })
    .catch((error) => {
      // console.log(error, " api error")
      return error;
    });
};
