import { combineReducers } from "@reduxjs/toolkit";
import LoginReducer from "./LoginReducer";
import ForgetPassReducer from "./ForgetPassReducer";
import NotificationReducer from "./NotificationReducer";
import NavigationReducer from "./NavigationReducer";
import UserProfileReducer from "./UserProfileReducer";

const RootReducer = combineReducers({
  loginInfo: LoginReducer,
  forgetPass: ForgetPassReducer,
  notification: NotificationReducer,
  navigation: NavigationReducer,
  userProfile: UserProfileReducer,
});

export default RootReducer;
