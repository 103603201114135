import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { setCookie } from "../../Frontend/Helpers/Session";
import Cookies from "js-cookie";

export const LoginSlice = createSlice({
  name: "login",
  initialState: {
    loginInfo: [],
    errors: [],
    authToken: [],
    refreshToken: [],
  },

  reducers: {
    getloginInfo: (state, action) => {
      state.loginInfo = action.payload;
      //   console.log(action, "reducer")
    },

    getLoginInfoSuccess: (state, action) => {
      state.loginInfo = action.payload;
      state.authToken = action.payload.data.data.access;
      state.refreshToken = action.payload.data.data.refresh;
      // console.log(action.payload, "reducer");
      setCookie("refresh_token_monitor", action.payload.data.data.refresh);
      setCookie("access_token_monitor", action.payload.data.data.access);
      toast.success("Logged In Successfully", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    getAuthToken: (state, action) => {
      state.authToken = action.payload;
      // console.log(action, "reducer");
    },
    getRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      // console.log(action, "reducer");
    },

    getErrors: (state, action) => {
      state.errors = action.payload.response.data;
      // console.log(state.errors, "reducer");

      toast.error(`${state.errors.error}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  },
});

<ToastContainer />;

export const {
  getloginInfo,
  getErrors,
  getLoginInfoSuccess,
  getAuthToken,
  getRefreshToken,
} = LoginSlice.actions;

export default LoginSlice.reducer;
