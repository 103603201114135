import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../Services/Interceptor";
import { FaFilter, FaUserTie } from "react-icons/fa";
import { IoFileTrayOutline } from "react-icons/io5";
import { MdOutlineTaskAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getNavLocation } from "../../Redux/Reducers/NavigationReducer";
import { TbAlertOctagon } from "react-icons/tb";
import { GoAlert, GoClock } from "react-icons/go";
import { BiSolidRightArrow } from "react-icons/bi";
import LoadingPage from "../Helpers/LoadingPage";

const Test = (props) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNavLocation(location));
  }, []);
  // loading
  const [isLoading, setIsLoading] = useState(true);
  const [processedNotificationData, setProcessNotificatioinData] = useState([]);

  const [investigationLog, setInvestigationLog] = useState([]);
  const [selectedLogId, setSelectedLogId] = useState(null);
  //getting process notification data
  useEffect(() => {
    axiosInstance
      .get(baseURL + "notification/operator/?state=Processed")
      .then((response) => {
        setProcessNotificatioinData(response.data);
        setSelectedLogId(response.data?.results.data[0].notification[0].id);
      })
      .catch((error) => {
        console.log(error, "  error");
      });
  }, []);

  // getting investigation log
  useEffect(() => {
    if (selectedLogId) {
      axiosInstance
        .get(baseURL + `notification/operator/process/${selectedLogId}/`)
        .then(function (response) {
          setInvestigationLog(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error.message, "  error");
          setIsLoading(false);
        });
    }
  }, [selectedLogId]);

  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState(null);
  useEffect(() => {
    if (pagination) {
      axiosInstance
        .get(pagination?.replace("http://", "https://"))
        .then((response) => {
          setProcessNotificatioinData(response.data);
        })
        .catch((error) => {
          console.log(error, "  error");
        });
    }
  }, [pagination]);

  const [next, setNext] = useState("");

  // const [openGenerateReport, setOpenGeneratereport] = useState(false);
  // const handleGeneratereport = () => {
  //   setOpenGeneratereport(false);
  //   setProcessedData(null);
  //   toast.success("Report has been generated", {
  //     position: "top-right",
  //     autoClose: 2000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  // };

  return (
    <div className="h-full h-dvh  container mx-auto  gap-2 font-raleway p-4 flex flex-col">
      <section className="w-full h-[40vh] grow ">
        <div className="flex gap-4 pt-6 border-b border-slate-400 text-sm font-medium capitalize">
          <div
            className={" border-b-2 border-primary text-primary cursor-pointer"}
          >
            The Processed Data Dashboard
          </div>
        </div>

        <div className="py-4 w-full h-[80%]  overflow-auto ">
          {isLoading ? (
            <LoadingPage />
          ) : (
            <>
              {processedNotificationData?.results?.data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="focus:border focus-within:border-blue-500 focus:border-red-500 focus:text-blue-400 "
                  >
                    <div className="sticky top-0 w-full bg-[#458CF7] py-2  font-semibold px-8 text-white">
                      {item.date}
                    </div>
                    <div className="w-full space-y-0 pr-3 divide-y divide-slate-600/20">
                      {item.notification?.map((subItem, subIndex) => (
                        <div
                          onClick={() => setSelectedLogId(subItem.id)}
                          key={subIndex}
                          className={`px-8 py-4 cursor-pointer flex justify-between items-start gap-2 group
                        hover:bg-brandColor/5 hover:shadow shadow-brandColor/30 hover:border-brandColor/40 text-gray-600
                        ${
                          selectedLogId === subItem.id
                            ? "border border-blue-500/50 rounded-md"
                            : "border-gray-300 "
                        }`}
                        >
                          <TbAlertOctagon className="h-6 w-6 text-slate-400" />
                          <div className="space-y-3 flex-1">
                            <p className="font-semibold">{subItem.content}</p>
                            <div className="flex justify-start items-center gap-4">
                              <div className="flex justify-center items-center gap-1 border group-hover:border-brandColor/50 w-max px-4 text-xs py-1 rounded-full font-medium">
                                <GoAlert className="h-3 w-3 text-slate-500 group-hover:text-brandColor" />
                                <p>{subItem.notification_type}</p>
                              </div>
                              <div className="flex justify-center items-center gap-1 border group-hover:border-brandColor/50 w-max px-4 text-xs py-1 rounded-full font-medium">
                                <FaUserTie className="h-3 w-3 text-slate-500 group-hover:text-brandColor" />
                                <p>{subItem.user?.hub_owner?.name}</p>
                              </div>
                              <div className="flex justify-center items-center gap-1 border group-hover:border-brandColor/50 w-max px-4 text-xs py-1 rounded-full font-medium">
                                <GoClock className="h-3 w-3 text-slate-500 group-hover:text-brandColor" />
                                <p>{subItem.time}</p>
                              </div>
                            </div>
                          </div>
                          <div className="hidden group-hover:block text-brandColor/60">
                            <BiSolidRightArrow className="h-10 w-10" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </section>

      <section className="capitalize  w-full bg-white">
        <div className="flex justify-between mb-4">
          <h2 className="text-lg text-gray-700 font-medium capitalize py-2">
            my outstanding task
          </h2>

          <div className="flex items-center gap-2 text-gray-500">
            <button className="px-4 py-2 border border-slate-500 flex items-center gap-1 rounded-md capitalize font-medium text-sm">
              <FaFilter className="w-4 h-4" /> filter
            </button>
            <button className="px-4 py-2 border border-slate-500 flex items-center gap-1 rounded-md capitalize font-medium text-sm">
              <IoFileTrayOutline className="w-4 h-4" /> save view
            </button>
          </div>
        </div>

        <div className="flex justify-between bg-gray-400/20 py-3 px-4 rounded-md">
          <div className="flex gap-2  ">
            <h2 className="text-sm font-medium bg-white p-2 rounded-md">
              post-incident task owner <span>includes</span> Mr.Mizan
            </h2>
            <h2 className="text-sm font-medium bg-white p-2 rounded-md">
              post-incident task owner <span>includes</span> Mr. Kabila
            </h2>
          </div>
          <div className="flex items-center gap-2.5 text-sm">
            <p>found 0 incidents</p>
            <p>clear all fillters x</p>
          </div>
        </div>
      </section>

      <section className=" h-[20vh] border border-slate-300 p-4 capitalize rounded-md overflow-auto">
        {investigationLog.length === 0 ? (
          <div className=" relative h-auto">
            <div className="absolute h-full w-1 border-l-2 border-slate-300 border-dashed  z-0 left-1"></div>
            <div className="pb-3 relative z-10 w-full flex justify-start items-center gap-x-2">
              <div className="relative h-2.5 w-2.5 bg-brandColor/70 rounded-full"></div>
              <p className="relative text-xs sm:text-sm flex justify-start items-center gap-x-2 w-full">
                No data available
              </p>
            </div>
          </div>
        ) : (
          investigationLog?.data.map((item, index) => {
            return (
              <div key={index} className=" relative h-auto ">
                <article className=" text-xs sm:text-sm  w-full h-auto py-2">
                  <span className="bg-gray-500/30 px-4 py-1 rounded-md">
                    {item?.date}
                  </span>
                  {item?.notification?.map((subItem, index) => {
                    return (
                      <div
                        key={index}
                        className=" relative h-auto ml-2 py-2 space-y-1"
                      >
                        <p className="relative text-xs sm:text-sm  gap-x-2 w-full">
                          {subItem?.content}
                        </p>

                        <p className="relative text-xs sm:text-sm  gap-x-2 w-full">
                          Time: {subItem?.time}
                        </p>
                        <p className="relative text-xs sm:text-sm flex justify-start items-center gap-x-2 w-full xl:pl-6">
                          Operator mesasage :{subItem?.message}
                        </p>
                      </div>
                    );
                  })}
                </article>
              </div>
            );
          })
        )}
      </section>
    </div>
  );
};

export default Test;
