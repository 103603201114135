import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import Test from "./Frontend/Helpers/Test";
import axiosInstance from "./Services/Interceptor";
import UserList from "./Frontend/Components/UserList/UserList";
import CompanyProfile from "./Frontend/Components/CompanyProfile/CompanyProfile";
import Objects from "./Frontend/Components/Objects/Objects";
import { Notification } from "./Frontend/Components/WebMessage/Notification";
import { useDispatch, useSelector } from "react-redux";
import {
  ChartBarIcon,
  HomeIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import Investigation from "./Frontend/Components/Home/Investigation";
import { getAuthToken, getRefreshToken } from "./Redux/Reducers/LoginReducer";
import { getUserData } from "./Redux/Reducers/UserProfileReducer";
import LoadingPage from "./Frontend/Helpers/LoadingPage";
import axios from "axios";
import { RiLogoutCircleRLine } from "react-icons/ri";
import audio from "./audio/alarm-no3-14864.mp3";
import ReactAudioPlayer from "react-audio-player";

const Home = React.lazy(() => import("./Frontend/Components/Home/Home"));
const Auth = React.lazy(() => import("./Frontend/Components/Auth/Auth"));

const UserProfile = React.lazy(() =>
  import("./Frontend/Components/UserProfile/UserProfile")
);
const Inventory = React.lazy(() =>
  import("./Frontend/Components/Inventory/Inventory")
);
const Records = React.lazy(() =>
  import("./Frontend/Components/Records/Records")
);
const Error = React.lazy(() => import("./Frontend/Shared/Error/Error"));

export const AllRoutes = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();

  let authTokens = Cookies.get("access_token_monitor")
    ? Cookies.get("access_token_monitor")
    : null;

  let refreshTokens = Cookies.get("refresh_token_monitor")
    ? Cookies.get("refresh_token_monitor")
    : null;

  useEffect(() => {
    dispatch(getAuthToken(authTokens));
    dispatch(getRefreshToken(refreshTokens));
  }, []);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { authToken, refreshToken } = useSelector((state) => state.loginInfo);
  const { navLocations } = useSelector((state) => state.navigation);
  const { userProfile } = useSelector((state) => state.userProfile);
  const navigation = [
    {
      name: "Dashboard",
      href: "/",
      icon: HomeIcon,
      current: navLocations.pathname === "/" ? true : false,
    },
    {
      name: "Records",
      href: "/records",
      icon: UsersIcon,
      current: navLocations.pathname === "/records" ? true : false,
    },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Get profile data
  useEffect(() => {
    if (authTokens) {
      axiosInstance
        .get(baseURL + "profile/users/operator/")
        .then((response) => {
          // console.log(response.data.data);
          dispatch(getUserData(response.data.data));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [authToken]);

  const handleLogOut = () => {
    // console.log("first")
    axios
      .post(
        baseURL + "users/logout/",
        {
          refresh_token: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {});
    Cookies.remove("access_token_monitor");
    Cookies.remove("refresh_token_monitor");
    dispatch(getAuthToken(null));
    dispatch(getRefreshToken(null));
  };

  const [audioStart, setAudioStart] = useState(false);

  const { notifications } = useSelector((state) => state.notification);
  useEffect(() => {
    if (notifications.data) {
      setAudioStart(true);
      const timer = setTimeout(() => {
        setAudioStart(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notifications]);

  return (
    <BrowserRouter>
      <section className="relative z-10 h-screen w-full bg-brandColor/0">
        {authTokens && authTokens ? (
          <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50 xl:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex ">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <XIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </Transition.Child>
                      {/* Sidebar component, swap this element with another sidebar if you like */}
                      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-2">
                        <div className="flex justify-between h-16 shrink-0 items-center ">
                          <img
                            className="h-12 w-12 border-2 borde-slate-500 rounded-full"
                            src={
                              userProfile.image
                                ? userProfile?.image
                                : "/images/userIcon.png"
                            }
                            alt="operator profile"
                          />
                          <p className="capitalize text-white">
                            {userProfile.full_name}
                          </p>
                        </div>
                        <nav className="flex flex-1 flex-col">
                          <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                          >
                            <li>
                              <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                  <li key={item.name}>
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        item.current
                                          ? "bg-brandColor text-white"
                                          : "text-indigo-200 hover:text-white hover:bg-brandColor",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      )}
                                    >
                                      <item.icon
                                        className={classNames(
                                          item.current
                                            ? "text-white"
                                            : "text-indigo-200 group-hover:text-white",
                                          "h-6 w-6 shrink-0"
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </nav>
                        <div
                          onClick={() => handleLogOut()}
                          className="w-full flex justify-center items-center gap-4  bg-red-600 text-sm font-semibold uppercase text-white py-2 rounded-full cursor-pointer"
                        >
                          <RiLogoutCircleRLine className="h-4 w-4" />
                          Logout
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
            {/* Static sidebar for desktop */}
            <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brandColor/80 px-6">
                {/* {audioStart ? (
                  <ReactAudioPlayer
                    className="hidden"
                    src={audio}
                    listenInterval={1000}
                    autoPlay
                    loop
                    volume={audio || 0.7}
                  />
                ) : (
                  ""
                )} */}

                <Link to="/" className="flex h-16 shrink-0 items-center">
                  <img
                    className="h-full w-auto"
                    src="/images/logoBangla.png"
                    alt="Your Company"
                  />
                </Link>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-brandColor text-white"
                                  : "text-indigo-200 hover:text-white hover:bg-brandColor",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-white"
                                    : "text-indigo-200 group-hover:text-white",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>

                    <li className="-mx-6 mt-auto">
                      <Link
                        to="/userProfile"
                        className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white bg-brandColor hover:bg-brandColor"
                      >
                        <img
                          className="h-8 w-8 rounded-full bg-brandColor border border-white p-0.5"
                          src={
                            userProfile.image
                              ? userProfile?.image
                              : "/images/userIcon.png"
                          }
                          alt="profile"
                        />
                        <span className="sr-only">Your profile</span>
                        <span aria-hidden="true">
                          {userProfile.full_name
                            ? userProfile?.full_name
                            : "System Operator"}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="sticky top-0 z-40 flex items-center gap-x-2 bg-brandColor px-4 py-4 shadow-sm sm:px-6 xl:hidden">
              <button
                type="button"
                className="-m-2.5 px-2.5 text-indigo-200 xl:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <ChartBarIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <Link
                to="/"
                className="flex-1 text-sm font-semibold leading-6 text-white"
              >
                <img
                  className="h-10 w-auto"
                  src="/images/logoBangla.png"
                  alt="Your Company"
                />
              </Link>
              <Link to="/userProfile">
                <span className="sr-only">Your profile</span>
                <img
                  className="h-10 w-10 rounded-full bg-brandColor border-2 border-slate-500 "
                  src={
                    userProfile.image
                      ? userProfile?.image
                      : "/images/userIcon.png"
                  }
                  alt="Your Company"
                />
              </Link>
            </div>
            <main className="py-4 2xl:py-10 xl:pl-72 h-screen ">
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <Home />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/investigation"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <Investigation />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path="/records"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <Records />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/objects"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <Objects />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path="/test"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <Test />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path="/userProfile"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <UserProfile />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path="/userList"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <UserList />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/inventory"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <Inventory />
                    </React.Suspense>
                  }
                />
                <Route
                  exact
                  path="/companyProfile"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <CompanyProfile />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path="/*"
                  element={
                    <React.Suspense fallback={<LoadingPage />}>
                      <Error />
                    </React.Suspense>
                  }
                />
              </Routes>

              <Notification />
            </main>
          </>
        ) : (
          <Routes>
            <Route
              exact
              path="/"
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <Auth />
                </React.Suspense>
              }
            />

            <Route
              exact
              path="/*"
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <Error />
                </React.Suspense>
              }
            />
          </Routes>
        )}
      </section>
      <ToastContainer />
    </BrowserRouter>
  );
};
