import React from "react";

const GeneralInfo = () => {
  return (
    <div className="">
      <h2 className="text-2xl font-bold">General Information</h2>
      <div className="flex justify-between items-start gap-x-6 pt-6">
        <div className="basis-4/6 space-y-6">
          <div className="space-y-2">
            <div className="flex flex-col justify-start items-start gap-y-2">
              <p className="basis-2/6 uppercase text-sm font-semibold text-gray-500">
                Account name
              </p>
              <p className="basis-4/6 text-xl">dfcsdfsdf</p>
            </div>
            <div className="flex flex-col justify-start items-start gap-y-2">
              <p className="basis-2/6 uppercase text-sm font-semibold text-gray-500">
                title
              </p>
              <p className="basis-4/6 text-xl">dfcsdfsdf</p>
            </div>
          </div>
          <div className=" pt-2">
            <div className="flex flex-col justify-start items-start gap-y-2">
              <p className="basis-2/6 uppercase text-sm font-semibold text-gray-500">
                Address
              </p>
              <p className="basis-4/6 text-xl">dfcsdfsdf</p>
            </div>
          </div>
          <div className="space-y-2 pt-2">
            <div className="flex flex-col justify-start items-start gap-y-2">
              <p className="basis-2/6 uppercase text-sm font-semibold text-gray-500">
                Phone Number
              </p>
              <p className="basis-4/6 text-xl">321654135468</p>
            </div>
            <div className="flex flex-col justify-start items-start gap-y-2">
              <p className="basis-2/6 uppercase text-sm font-semibold text-gray-500">
                Working schedule
              </p>
              <p className="basis-4/6 text-xl">10.20.30</p>
            </div>
            <div className="flex flex-col justify-start items-start gap-y-2">
              <p className="basis-2/6 uppercase text-sm font-semibold text-gray-500">
                Password
              </p>
              <p className="basis-4/6 text-xl">********</p>
            </div>
          </div>
        </div>
        <div className="basis-2/6">
          <img
            className=" h-44 w-44 bg-white mx-auto object-contain rounded-full border-[2px] border-yellow-500 p-2"
            src="/images/zicore.png"
            alt="Workflow"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
