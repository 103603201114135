import React from "react";

const LoadingPage = () => {
  return (
    <div className="relative  flex justify-center items-center w-full h-full">
      <div className="ringAnimation"></div>
      <div className="ringAnimation"></div>
      {/* <div className="ringAnimation"></div> */}
      
      <div className="absolute ">
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default LoadingPage;
